import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificationService } from 'src/app/services/notification.service';
import { SuscriptionsService } from 'src/app/services/suscription/suscriptions.service';
import { WebService } from 'src/app/services/web.service';
import { ThemeService } from '../../theme.service';
import { UpdateSuscriptionComponent } from '../update-suscription/update-suscription.component';
@Component({
  selector: 'app-mis-suscripciones',
  templateUrl: './mis-suscripciones.component.html',
  styleUrls: ['./mis-suscripciones.component.scss']
})
export class MisSuscripcionesComponent {

  suscriptions = [];

  themeColors: any; 
  isDialogOpen = false;
  constructor(public themeService: ThemeService,private dialog: MatDialog, private authService : AuthService,private suscriptionService : SuscriptionsService ,private web : WebService, private notification : NotificationService, private loader : LoaderService ){}

  changePalette(palette: string) {
    this.themeService.setPalette(palette);
  }
  toggleupdatesub() {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true; // Marca que el diálogo está abierto
      const dialogRef = this.dialog.open(UpdateSuscriptionComponent, {
        width: '800px',height:'650px'
        
      });
      dialogRef.afterClosed().subscribe(() => {
        // Restablece el indicador cuando se cierra el diálogo
        this.isDialogOpen = false;
        window.location.reload();
      });
  } 
}

ngOnInit(): void {
  const customer = this.authService.getCustomer();
  const service = this.authService.getService();
  //console.log('Customer ID from AuthService:', customer); // Agregado //console.log
  //console.log('Service ID from AuthService:', service); // Agregado //console.log

  this.loadSubscriptions();
}

loadSubscriptions(): void {
  this.loader.open();
  this.suscriptions = [];
  
  // Usar valores obtenidos del AuthService
  const customer = this.authService.getCustomer();
  const service = this.authService.getService();
  

  //console.log('Using Customer ID:', customer); // Agregado //console.log
  //console.log('Using Service ID:', service); // Agregado //console.log

  const url = `${this.web.HOST}/hasplan/customer/${customer}/service/${service}`;
  //console.log('Fetching subscriptions from URL:', url); // Agregado //console.log

  this.web.get(url).subscribe(response => {
    //console.log('API response:', response); // Agregado //console.log

    if (response && response.result) {
      for (let sus of response.result) {
        sus.status = sus.status_customerPlan;
        switch (sus.status_customerPlan) {
          case 1: sus.status_customerPlan = "Activa"; break;
          case 2: sus.status_customerPlan = "Inactiva"; break;
          case 3: sus.status_customerPlan = "Congelada"; break;
          case 4: sus.status_customerPlan = "En proceso de cobro"; break;
          case 5: sus.status_customerPlan = "No se pudo cobrar"; break;
        }
        this.suscriptions.push(sus);
      }
    } else {
      //console.log('No subscriptions found in the response'); // Agregado //console.log
    }

    this.loader.close();
    this.suscriptionService.suscriptions.emit(this.suscriptions);
  }, err => {
    this.loader.close();
    this.notification.showError(err);
    //console.log('Error fetching subscriptions:', err); // Agregado //console.log
  });
}






}
