import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  httpOptions: any;
  public HOST: string = environment.backendUrl;

  constructor(private http: HttpClient) {
    // Http Headers
    this.httpOptions = {}
  }

  get(url: string, params: HttpParams = new HttpParams()): Observable<any> {
    this.httpOptions.params = params;
    return this.http.get(url, this.httpOptions)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  send(object: any, url: string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse | any) {
    if (error instanceof Error) {
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      if (error.error != undefined) {
        return throwError(error.error);
      } else {
        return throwError(error);
      }
    }
    return throwError(error);
  }

  suscribe(object: any, url: string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  post(object: any, url: string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

  extractData(res: any) {
    if (res !== null) {
      return res.result;
    }
    return res;
  }

  auth(object: any, url: string): Observable<any> {
    return this.http.post(url, object, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  put(object: any, url: string): Observable<any> {
    return this.http.put(url, object, this.httpOptions)
      .pipe(
        map(this.extractData),
        catchError(this.handleError)
      );
  }

}
